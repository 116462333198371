import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import Intro from "../components/Intro";
import Section from "../components/Section";
import SharingButtons from '../components/SharingButtons';
import Seo from "../components/Seo2";

const Template = ({ children, data, location, navigate, pageContext, pageResource, path, uri }) => {
	// console.log("BlogPost", pageContext.canonical);
	// console.log("BlogPost", children, data, location, pageContext, pageResource, path, uri);
	const { markdownRemark } = data // data.markdownRemark holds our post data
	const { frontmatter, html } = markdownRemark
	return <Layout>
		<Seo {...frontmatter} canonical={frontmatter.canonical || frontmatter.path} />
		<div className="container">
			<Intro heading={frontmatter.title} imageSrc={frontmatter.image} imageAlt={frontmatter.imageAlt} />
		</div>
		<Section container={true} padding="none" margin="medium">
			<div className="mt-3" dangerouslySetInnerHTML={{ __html: html }} />
			<hr />
			<div className="text-right">
				<SharingButtons subject={frontmatter.title} url={"https://b-engaged.se" + pageContext.canonical} />
			</div>
		</Section>
	</Layout>
}

export const pageQuery = graphql`
  query($canonical: String!) {
    markdownRemark(frontmatter: { path: { eq: $canonical } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title,
        image
      }
    }
  }
`
export default Template;